import React, {useEffect} from 'react';
import {loginAsync, logout} from "../domain/redux/actions";
import {useDispatch} from "react-redux";
import AuthenticationService from "./AuthenticationService";

export let useIdentity = () => {
    let dispatch = useDispatch();

    return {
        refresh: () => {
            refreshIdentity().then(dispatch)
        }
    }
}

export let refreshIdentity = (loginAsync, logout) => {
    return AuthenticationService.getIdentity()
        .then(data => handleIdentityResult(data, false))
}

export let handleIdentityResult = (identityResponse, isNewLogin) => {
    if (identityResponse.isAuthenticated) {
        return loginAsync(identityResponse, isNewLogin)
    } else {
        return logout()
    }
}

let AuthenticationChecker = () => {
    let identity = useIdentity();

    useEffect(() => {
        identity.refresh()
    }, [])

    return <></>
};

export default AuthenticationChecker;
