import * as axi from "axios";
import {loggedOut, unexpectedErrorOccurred} from "../../helpers";
import {tryLogout} from "../../../domain/redux/actions";
import {store} from "../../../shared";

let axios = axi.create({
    baseURL: window && window.CONFIG && window.CONFIG.BACKEND_BASE_URL, // unit test safety
    withCredentials: true, // TODO should not be enabled in prod?
    maxRedirects: 0
});

axios.interceptors.response.use(
    res => res,
    err => {
        if (err.response) {
            if (err.response.status >= 500) {
                unexpectedErrorOccurred()
                throw err
            }

            if (err.response.status === 401) {
                loggedOut()
                store.dispatch(tryLogout())
                return Promise.reject(err)
            }

            throw err
        }

        throw err;
    }
)

export default axios;
