import {Helmet} from "react-helmet";
import {useLocation, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useLocalizedRoutes} from "@commons/infra/i18n/routes";
import {LANGUAGE_CODE_ENGLISH, LANGUAGE_CODE_LATVIAN, LANGUAGE_CODE_RUSSIAN} from "@commons/infra/i18n/consts";

export let MetaLinksHelmet = ({ignoredQueryParams = [], alternateLanguages = [], defaultLanguageCode}) => {
    let {pathname, search, key} = useLocation();
    let {locale} = useIntl()
    let [canonicalUrl, setCanonicalUrl] = useState();
    let [alternateLinks, setAlternateLinks] = useState([]);
    let params = useParams()
    let lr = useLocalizedRoutes()

    useEffect(() => {
        let filteredQueryParams = filterSearchParams(ignoredQueryParams, search);

        // Alternate links
        setAlternateLinks(alternateLanguages.map(l => ({
            code: l,
            url: window.CONFIG.FRONTEND_BASE_URL + lr.getCurrentUrl(l)
        })))

        // Canonical
        let canonicalUrl = containsLanguageCode(pathname, [...alternateLanguages, defaultLanguageCode])
            ? window.CONFIG.FRONTEND_BASE_URL + `/${defaultLanguageCode}` + pathname.substring(3) + filteredQueryParams
            : window.CONFIG.FRONTEND_BASE_URL + `/${defaultLanguageCode}` + pathname + filteredQueryParams;
        setCanonicalUrl(canonicalUrl)
    }, [alternateLanguages, locale, pathname, search, params, key]);

    return <>
        <Helmet>
            <link rel="canonical" href={canonicalUrl}/>
            {alternateLinks.map(al => <link rel="alternate" href={al.url} hrefLang={al.code}/>)}
            {window.CONFIG.SEO.noindex && <meta name="robots" content="noindex"/>}
        </Helmet>
    </>
}

export let LatvianMetaLinksHelmet = () => <MetaLinksHelmet
    alternateLanguages={[LANGUAGE_CODE_RUSSIAN, LANGUAGE_CODE_ENGLISH]}
    ignoredQueryParams={["rp"]}
    defaultLanguageCode={LANGUAGE_CODE_LATVIAN}
/>

let filterSearchParams = (ignoredQueryParams, currentQueryParams) => {
    let filteredSearchParams = new URLSearchParams(currentQueryParams);
    ignoredQueryParams.forEach(param => {
        filteredSearchParams.delete(param)
    })
    return (filteredSearchParams.size > 0 ? '?' + filteredSearchParams.toString() : '')
}

let containsLanguageCode = (pathname, possibleLanguageCodes) => possibleLanguageCodes.some(code => pathname.startsWith(`/${code}`));
