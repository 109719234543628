import {TickedListItem, TickedListItemWithoutTitle} from "@commons/ui/component/UnorderedList";
import React from "react";
import YouTubeVideo from "@commons/ui/component/YouTubeVideo";
import {MARK_LINK, NODE_HEADING, NODE_LI, NODE_OL, NODE_UL, render} from 'storyblok-rich-text-react-renderer';
import {parseI18nLink} from "@commons/infra/storyblok/i18nLinks";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {TARGET_BLANK_PROPS} from "@commons/infra/helpers";

export let RichText = ({content}) => {
    return <>{render(content, {
        blokResolvers: {
            ['YoutubeVideo']: (props) => <div className={"mb-4"}>
                <YouTubeVideo
                    youtubeVideoId={props.VideoId}
                    placeholderVideoId={props.VideoId}
                />
            </div>,
            ['TickedListItem']: (props) => <TickedListItem
                title={props.Title}
                compensateInnerParagraph={true} // RichText field wraps things into <p> automatically
                text={<RichText content={props.Text}/>}
            />
        },
        markResolvers: {
            [MARK_LINK]: (children, {linktype, href, target, anchor, uuid, custom}) => {
                let newHref = href.replace(window.CONFIG.FRONTEND_BASE_URL, "");

                return <LocalizedLink
                    to={parseI18nLink(newHref)}
                    {...newHref.startsWith("https://") ? TARGET_BLANK_PROPS : {}}
                >
                    {children}
                </LocalizedLink>
            },
            [NODE_HEADING]: (children, {level}) => <h4 className={"mt-4"}>
                {children}
            </h4>
        },
        nodeResolvers: {
            [NODE_OL]: (children) => <div className={"mb-3"}>{children}</div>,
            [NODE_UL]: (children) => <div className={"mb-3"}>{children}</div>,
            [NODE_LI]: (children) => <TickedListItemWithoutTitle
                className={"mb-0"}
                icon={"ai-arrow-right"}>
                {children}
            </TickedListItemWithoutTitle>,
        }
    })}</>
}