import _ from "lodash";
import {useLocation} from "react-router-dom";
import React, {useState} from "react";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {LanguageList} from "@commons/infra/i18n/LocalizedSwitcher";

let Navigation = ({items, onLinkClick, showLanguageSelector = true}) => {
    let location = useLocation()

    return <ul className="navbar-nav p-0 me-auto">
        {items.map((item) => {
            let selected = location.pathname === item.to;

            if (!_.isUndefined(item.if) && !item.if()) {
                return <></>
            }

            if (item.items) {
                return <NavItemWithChildren className={item.className} to={item.to} items={item.items} text={item.text}
                                            onLinkClick={onLinkClick}/>
            }

            if (item.megamenu) {
                return <Megamenu className={item.className} to={item.to} columns={item.megamenu} text={item.text}
                                 onLinkClick={onLinkClick}/>
            }

            return <>
                {item.separator && <hr className={"my-2"}/>}
                <li key={item.to}
                    className={`nav-item dropdown ${selected ? 'active' : ''} ${item.className || ''}`}>

                    {item.href && <a className={`nav-link ${item.items ? 'dropdown-toggle' : ''}`}
                                     href={item.href}
                                     target={item.target}>
                        {item.text}
                    </a>}

                    {item.onLinkClick && <a className={`nav-link ${item.items ? 'dropdown-toggle' : ''}`}
                                            href={'/'}
                                            onClick={e => item.onLinkClick(e)}>
                        {item.text}
                    </a>}

                    {!item.onLinkClick && !item.href &&
                        <LocalizedLink className={`nav-link ${item.items ? 'dropdown-toggle' : ''}`}
                                       data-bs-toggle="dropdown"
                                       to={item.to}
                                       onClick={onLinkClick}>
                            {item.text}
                        </LocalizedLink>}
                </li>
            </>;
        })}

        {window.CONFIG.FEATURES.I18N_SELECTOR && showLanguageSelector && <>
            <span className="navbar-text">
                <hr/>
            </span>
            <LanguageList className={"d-block d-md-none"} linkClassName={"nav-link"}/>
        </>}
    </ul>;
};

let NavItemWithChildren = ({items, text, className, onLinkClick}) => {
    let selected = false;
    let [active, setActive] = useState(false);
    let onClick = (e) => {
        e.preventDefault();
        setActive(!active);
    }

    return <li className={`nav-item dropdown ${selected ? 'active' : ''} ${className || ''} ${active ? 'show' : ''}`}>
        <a className={`nav-link dropdown-toggle`} href={"/#"} onClick={onClick}>
            {text}
        </a>
        <ul className={`dropdown-menu ${active ? 'show' : ''}`}>
            {items.map(item =>
                <>
                    {item.separator && <li className="dropdown-divider">
                        <hr/>
                    </li>}
                    <li>
                        {item.to && <LocalizedLink to={item.to} className="dropdown-item" onClick={onLinkClick}
                                                   skipLocalization={item.skipLocalization}>
                            {item.text}
                        </LocalizedLink>}
                        {item.onLinkClick && <a href="/#" className="dropdown-item" onClick={(e) => {
                            item.onLinkClick(e);
                            onLinkClick(e);
                        }}>
                            {item.text}
                        </a>}
                    </li>
                </>
            )}
        </ul>
    </li>;
}

let Megamenu = ({columns, text, className, onLinkClick}) => {
    let selected = false;
    let [active, setActive] = useState(false);
    let onClick = (e) => {
        e.preventDefault();
        setActive(!active);
    }

    return <li className={`nav-item dropdown ${selected ? 'active' : ''} ${className || ''} ${active ? 'show' : ''}`}>
        <a className={`nav-link dropdown-toggle`} href={"/#"} onClick={onClick}>
            {text}
        </a>
        <div className={`dropdown-menu ${active ? 'show' : ''}`}>
            <div className="d-lg-flex">
                {columns.map(column => <div
                    className={`mega-dropdown-column ${column.continuation ? 'pt-lg-1' : 'pt-1'} pt-lg-3 pb-lg-4`}>
                    {column.title && <h6 className="mb-0 ps-1 ms-3 mb-2 mt-lg-0 mt-3">
                        {column.title}
                    </h6>}
                    {column.continuation && <h6 className="d-none d-lg-block mb-0 ps-1 ms-3 mb-2 mt-lg-0 mt-3">
                        &nbsp;
                    </h6>}
                    {column.items.map(item =>
                        <>
                            {item.separator && <li className="dropdown-divider">
                                <hr/>
                            </li>}
                            <li>
                                {item.to && <LocalizedLink to={item.to} className="dropdown-item" onClick={onLinkClick}>
                                    {item.text}
                                </LocalizedLink>}
                                {item.onLinkClick && <a href="/#" className="dropdown-item" onClick={(e) => {
                                    item.onLinkClick(e);
                                    onLinkClick(e);
                                }}>
                                    {item.text}
                                </a>}
                            </li>
                        </>
                    )}
                </div>)}
            </div>
        </div>
    </li>;
}

export default Navigation
