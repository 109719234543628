import React, {useEffect, useState} from 'react';
import {useQuery} from "@commons/infra/helpers";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {localStorageWrapper} from "@commons/infra/localstorage";

let CUSTOM_REDIRECT_PATH_PARAM = 'rp';

/**
 * @return string|null
 */
let getCustomRedirectPathFromURL = (query) => query.get(CUSTOM_REDIRECT_PATH_PARAM);

let resolveCustomRedirectPath = (query) => {
    let fromLocalStorage = localStorageWrapper.get(CUSTOM_REDIRECT_PATH_PARAM);
    let fromPath = getCustomRedirectPathFromURL(query);

    return fromPath || fromLocalStorage
}

/**
 * Saves post-login path into localstorage
 */
export let CustomRedirectSaver = () => {
    let [customRedirectPath] = useState(getCustomRedirectPathFromURL(useQuery()));
    useEffect(() => {
        if (!customRedirectPath) return
        localStorageWrapper.set(CUSTOM_REDIRECT_PATH_PARAM, customRedirectPath, 1000 * 60 * 60)
    }, [customRedirectPath])

    return <></>
}

const mapStateToProps = (state) => ({
    authentication: state.authentication
})

/**
 * Redirects client to post-login path if it is provided
 * Post-login path resolution chain:
 * - localstorage
 * - query parameter
 */
export let PostLoginRedirectHandler = connect(mapStateToProps)(({authentication, redirectResolver}) => {
    let [customRedirectPath] = useState(resolveCustomRedirectPath(useQuery()));
    let [redirectPath, setRedirectPath] = useState(undefined)

    useEffect(() => {
        if (authentication.isAuthenticated) {
            localStorageWrapper.remove(CUSTOM_REDIRECT_PATH_PARAM)
            setRedirectPath(redirectResolver(authentication.authorities, customRedirectPath))
        }
    }, [authentication, customRedirectPath])

    let navigate = useNavigate()
    useEffect(() => {
        if (!redirectPath)
            return

        if (redirectPath.external) {
            window.location = redirectPath.to
            return
        }

        navigate(redirectPath.to, {replace: true})
    }, [redirectPath, navigate])

    return <></>
})
