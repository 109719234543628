import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import React from "react";
import {BackgroundVideo} from "@commons/infra/video";

export let HeadingSectionV1 = ({background = 'secondary', title, text, buttonLink, buttonText, imgSrc, imgAlt}) => {
    return <section className={`py-4 py-md-5 bg-${background}`}>
        <div className={`container position-relative zindex-5 py-sm-0 py-lg-0 mt-xxl-4`}>
            <div className="row pt-lg-2 py-xl-0 py-xxl-0 mb-md-0 mb-lg-1">
                <div className="order-1 order-lg-0 col-md-10 col-lg-9 col-xl-7 col-xxl-7 pt-0 pb-md-5 pb-xxl-5">
                    <h1 className="display-4 pb-sm-2">
                        {title}
                    </h1>
                    <p className="pb-1 pb-md-2 fs-xl" style={{maxWidth: '520px'}}>
                        {text}
                    </p>
                    <LocalizedLink to={buttonLink}
                                   className="btn btn-lg btn-primary px-4">
                        {buttonText}
                    </LocalizedLink>
                </div>
                <div className="order-0 order-lg-1 px-5 px-lg-0 col-md-2 col-lg-3 col-xl-5 col-xxl-5 pt-0 mb-4 mb-lg-5">
                    <img src={imgSrc} alt={imgAlt}/>
                </div>
            </div>
        </div>
    </section>;
}

export let HeroWithVideoSection = ({
                                       backgroundClass = 'bg-nude',
                                       videoUrl,
                                       videoPoster,
                                       title,
                                       text,
                                       buttonText,
                                       buttonLink,
                                       secondaryButtonText,
                                       secondaryButtonLink
                                   }) => {
    return <section className={`${backgroundClass} bg-opacity-10 d-flex overflow-hidden pb-4`}>
        <div className="container d-flex justify-content-center pb-sm-3 py-md-4 py-xl-5">
            <div className="row align-items-center pt-5 mt-2 mt-lg-4 mt-xxl-0">
                <div className="col-lg-6 mb-4 mb-lg-0 pb-3 pb-lg-0">
                    <div className="parallax mx-auto mx-lg-0" style={{maxWidth: '582px'}}>
                        <div className="parallax-layer z-3" data-depth="0.1">
                            <div className="position-relative bg-dark mx-auto z-3" style={{
                                maxWidth: '61%',
                                padding: '.3125rem',
                                marginBottom: '9.9%',
                                borderRadius: 'calc(var(--ar-border-radius) * 2.125)',
                                zIndex: 999,
                                minHeight: 350
                            }}>
                                <BackgroundVideo
                                    className="d-block w-100 z-3"
                                    poster={videoPoster}
                                    style={{borderRadius: 'calc(var(--ar-border-radius) * 1.875)'}}
                                    src={videoUrl}/>
                            </div>
                        </div>
                        <div className="parallax-layer z-1" data-depth="0.3">
                            <img src="/marketing-agency/hero/shape01.svg" alt="Background shape"/>
                        </div>
                        <div className="parallax-layer z-2" data-depth="-0.1">
                            <img src="/marketing-agency/hero/shape02.svg" alt="Background shape"/>
                        </div>
                        <div className="parallax-layer z-1" data-depth="-0.15">
                            <img src="/marketing-agency/hero/shape03.svg" alt="Background shape"/>
                        </div>
                        <div className="parallax-layer z-2" data-depth="-0.25">
                            <img src="/marketing-agency/hero/shape04.svg" alt="Background shape"/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 text-center text-lg-start">
                    <h1 className="display-2 pb-3 mb-4">
                        {title}
                    </h1>
                    <p className={"fs-5"}>
                        {text}
                    </p>
                    <div className="d-sm-flex justify-content-center justify-content-lg-start pt-4 mt-lg-2">
                        <LocalizedLink to={buttonLink}
                                       className="btn btn-lg btn-primary w-100 w-sm-auto mb-2 mb-sm-0 me-sm-1">
                            {buttonText}
                            <i className="ai-arrow-right ms-2"></i>
                        </LocalizedLink>
                        {secondaryButtonLink && secondaryButtonText &&
                            <LocalizedLink to={secondaryButtonLink} className="btn btn-lg btn-link">
                                {secondaryButtonText}
                            </LocalizedLink>}
                    </div>
                </div>
            </div>
        </div>
    </section>
}