import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from "react-gtm-module";
import {defaultAppConfig} from "./config";

if (window.CONFIG.GOOGLE_TAG_MANAGER.ENABLED) {
    TagManager.initialize({
        gtmId: window.CONFIG.GOOGLE_TAG_MANAGER.GTM_ID,
    })
}

let root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <App config={defaultAppConfig}/>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
