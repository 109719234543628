import PageHeading1 from "@commons/infra/storyblok/components/PageHeading1";
import Page from "@commons/infra/storyblok/components/Page";
import Row1 from "@commons/infra/storyblok/components/Row1";
import VideoSwiper from "@commons/infra/storyblok/components/VideoSwiper";
import Cta from "@commons/infra/storyblok/components/Cta";
import SpecialityDoctorSwiper from "@commons/infra/storyblok/components/SpecialityDoctorSwiper";
import HeroWithVideoSection from "@commons/infra/storyblok/components/HeroWithVideoSection";
import Faq from "@commons/infra/storyblok/components/Faq";
import {Benefits} from "@commons/infra/storyblok/components/Benefits";
import {Steps} from "@commons/infra/storyblok/components/Steps";
import {Doctors} from "@commons/infra/storyblok/components/Doctors";

let storyblokComponentsMapping = {
    SpecialityPage: Page,
    LandingPage: Page,
    HeroWithVideo: HeroWithVideoSection,
    PageHeading1: PageHeading1,
    Row1: Row1,
    VideoSwiper: VideoSwiper,
    Cta: Cta,
    FAQ: Faq,
    SpecialityDoctorSwiper: SpecialityDoctorSwiper,
    Benefits: Benefits,
    Steps: Steps,
    Doctors: Doctors
}

export default storyblokComponentsMapping
