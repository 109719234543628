import {FullWidthSection2} from "@commons/ui/component/Section";
import React from "react";
import {RichText} from "@commons/infra/storyblok/richText";

let Row1 = ({blok}) => <FullWidthSection2
    imgSrc={blok.Illustration.filename}
    imgAlt={blok.Illustration.alt}
    className={blok.GreyBackground ? "bg-secondary" : ""}
    hideImageOnMobile={false}
    contentOnRightSide={blok.IllustrationPosition === 'left'}
    title={blok.Title}
    content={<RichText content={blok.Content}/>}
/>

export default Row1
