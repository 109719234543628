import {Spinner} from "react-bootstrap";
import React from "react";
import {toast} from "react-toastify";


let Loading = ({size = 2, spinnerSize = null, className}) => {
    return <Spinner size={spinnerSize} animation="border" role="status"
                    style={{opacity: 0.4, width: `${size}rem`, height: `${size}rem`}}
                    className={className}>
        <span className="visually-hidden">Loading...</span>
    </Spinner>
};

export let LoadingBlock = () => {
    return <div className="my-3">
        <Loading/>
    </div>;
};

export let LoadingButton = ({
                                className,
                                style,
                                onClick,
                                isLoading,
                                children,
                                isDisabled = false,
                                type,
                            }) => {

    let onClickWrapper = (e) => {
        toast.dismiss()
        if (onClick)
            onClick(e)
    }

    return <button className={className} style={style} type={type} disabled={isLoading || isDisabled}
                   onClick={onClickWrapper}>
        {children}
        {isLoading && <CustomSpinner/>}
    </button>
}

export let CustomSpinner = () => {
    return <span className="spinner-grow spinner-grow-sm me-2"
                 role="status"
                 style={{opacity: 0.4, marginBottom: 4, marginLeft: 12}}
                 aria-hidden="true"/>
}

export let CustomSpinner2 = () => {
    return <span className="spinner-border spinner-border-sm ms-2"
                 role="status"
                 style={{opacity: 1}}
                 aria-hidden="true"/>
}

export default Loading;
