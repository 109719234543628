import {all, put, takeEvery} from 'redux-saga/effects'
import {
    FETCH_DOCTOR_DAILY_OVERVIEW_ASYNC,
    FETCH_DOCTOR_MONTHLY_OVERVIEW_ASYNC,
    FETCH_DOCTOR_PROFILE_ASYNC,
    FETCH_ONGOING_RESERVATIONS_ASYNC,
    FETCH_PATIENT_PROFILE_ASYNC,
    FETCH_SO_PATIENT_PROFILE_ASYNC,
    fetchDoctorDailyOverviewAsync,
    fetchDoctorMonthlyOverviewAsync,
    fetchDoctorProfileAsync,
    fetchedDoctorDailyOverview,
    fetchedDoctorMonthlyOverview,
    fetchedDoctorProfile,
    fetchedOngoingReservations,
    fetchedPatientProfile,
    fetchOngoingReservations,
    fetchPatientProfileAsync,
    fetchSoPatientProfileAsync,
    login,
    LOGIN_ASYNC,
    logout,
    TRY_LOGIN,
    TRY_LOGIN_FAILED,
    TRY_LOGOUT,
    TRY_STOP_IMPERSONATING,
} from "./actions.js";
import {AUTHORITY_DOCTOR, AUTHORITY_PATIENT, AUTHORITY_SO_PATIENT} from "../../infra/consts";
import {handleIdentityResult} from "../../infra/AuthenticationChecker";
import {registerCustomFacebookEvent, registerGaEvent} from "../../infra/helpers";
import {PatientProfileApi} from "../../api/patient/ProfileApi";
import {ProfileApi} from "../../api/doctor/ProfileApi";
import {OverviewApi} from "../../api/doctor/OverviewApi";
import AuthenticationService from "../../infra/AuthenticationService";

// Fake delayed promise, test helper, please don't delete
// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line
const delay = (ms = 500) => new Promise(res => setTimeout(() => res(true), ms));

function* tryLoginSaga({payload}) {
    let loginResult = yield AuthenticationService.login(payload.email, payload.password);

    if (!loginResult.success) {
        registerGaEvent({
            'event': 'login_failed',
            'client_type': 'doctor',
        }, null, 0)
        yield put({type: TRY_LOGIN_FAILED})
        return;
    }

    let identity = yield AuthenticationService.getIdentity();
    yield put(handleIdentityResult(identity, true))
}

function* tryLogoutSaga({payload}) {
    yield AuthenticationService.logout();
    yield put(logout(payload.useReturnPath))
}

function* tryStopImpersonatingSaga() {
    yield AuthenticationService.stopImpersonating();
    let identity = yield AuthenticationService.getIdentity();
    yield put(handleIdentityResult(identity, false))
    window.location.reload();
}

function* fetchPatientProfileAsyncSaga() {
    let patientProfile = yield PatientProfileApi.getProfile()

    yield put(fetchedPatientProfile(patientProfile.data))
}

function* fetchSoPatientProfileAsyncSaga() {
    let patientProfile = yield PatientProfileApi.getSoProfile()

    yield put(fetchedPatientProfile(patientProfile.data))
}

function* fetchPatientOngoingReservationsSaga() {
    let ongoingReservations = yield PatientProfileApi.getOngoingReservations()

    yield put(fetchedOngoingReservations(ongoingReservations.data))
}

function* fetchDoctorMonthlyOverviewAsyncSaga() {
    try {
        let monthlyOverview = yield OverviewApi.getMonthlyOverview()
        yield put(fetchedDoctorMonthlyOverview(monthlyOverview.data))
    } catch (e) {
    }
}

function* fetchDoctorDailyOverviewAsyncSaga() {
    try {
        let dailyOverview = yield OverviewApi.getDailyOverview()
        yield put(fetchedDoctorDailyOverview(dailyOverview.data))
    } catch (e) {
    }
}

function* fetchDoctorProfileAsyncSaga() {
    let doctorProfile = yield ProfileApi.getProfile()

    yield put(fetchedDoctorProfile(doctorProfile.data))
}

function* loginAsyncSaga({payload}) {
    if (payload.authorities.includes(AUTHORITY_DOCTOR)) {
        yield put(fetchDoctorMonthlyOverviewAsync())
        yield put(fetchDoctorDailyOverviewAsync())
        yield put(fetchDoctorProfileAsync())
        if (payload.isNewLogin) {
            registerGaEvent({
                'event': 'login_successful',
                'user_id': payload.userReferenceId,
                'client_type': 'doctor',
            }, null, 0)
        }
    } else if (payload.authorities.includes(AUTHORITY_PATIENT)) {
        yield put(fetchPatientProfileAsync())
        if (payload.isNewLogin) {
            registerGaEvent({
                'event': 'login_successful',
                'user_id': payload.userReferenceId,
                'client_type': 'patient',
            }, null, 0)
            registerCustomFacebookEvent('Login', {
                'patient_id': payload.userReferenceId
            }, payload.publicSessionId)
        }
        yield put(fetchOngoingReservations())
    } else if (payload.authorities.includes(AUTHORITY_SO_PATIENT)) {
        yield put(fetchSoPatientProfileAsync())
        if (payload.isNewLogin) {
            registerGaEvent({
                'event': 'login_successful',
                'user_id': payload.userReferenceId,
                'client_type': 'patient',
            }, null, 0)
            registerCustomFacebookEvent('Login', {
                'patient_id': payload.userReferenceId,
            }, payload.publicSessionId)
        }
        yield put(fetchOngoingReservations())
    }

    // Accept Cookies on login - user has accepted everything in T&C
    // noinspection JSUnresolvedReference
    if (typeof CookieScript !== 'undefined' && CookieScript.instance) {
        // noinspection JSUnresolvedReference
        CookieScript.instance.acceptAllAction()
    }

    yield put(login(payload.email, payload.authorities, payload.publicSessionId, payload.isImpersonating, payload.userId));
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        yield takeEvery(TRY_LOGIN, tryLoginSaga),
        yield takeEvery(TRY_LOGOUT, tryLogoutSaga),
        yield takeEvery(TRY_STOP_IMPERSONATING, tryStopImpersonatingSaga),
        yield takeEvery(LOGIN_ASYNC, loginAsyncSaga),
        yield takeEvery(FETCH_PATIENT_PROFILE_ASYNC, fetchPatientProfileAsyncSaga),
        yield takeEvery(FETCH_SO_PATIENT_PROFILE_ASYNC, fetchSoPatientProfileAsyncSaga),
        yield takeEvery(FETCH_ONGOING_RESERVATIONS_ASYNC, fetchPatientOngoingReservationsSaga),
        yield takeEvery(FETCH_DOCTOR_PROFILE_ASYNC, fetchDoctorProfileAsyncSaga),
        yield takeEvery(FETCH_DOCTOR_MONTHLY_OVERVIEW_ASYNC, fetchDoctorMonthlyOverviewAsyncSaga),
        yield takeEvery(FETCH_DOCTOR_DAILY_OVERVIEW_ASYNC, fetchDoctorDailyOverviewAsyncSaga)
    ])
}
