import {
    CLEAR_ONGOING_RESERVATIONS,
    FETCH_ONGOING_RESERVATIONS_ASYNC,
    FETCHED_ONGOING_RESERVATIONS,
    FETCHED_PATIENT_PROFILE
} from "./actions.js";
import _ from "lodash";

let initialState = {
    profile: {},
    ongoingReservation: {
        exists: false,
        isLoading: false,
        appointment: {}
    }
};

export default function patientReducer(state = initialState, action) {
    switch (action.type) {
        case FETCHED_PATIENT_PROFILE:
            return {
                ...state,
                profile: action.payload
            };

        case FETCH_ONGOING_RESERVATIONS_ASYNC:
            return {
                ...state,
                ongoingReservation: {
                    exists: false,
                    isLoading: true,
                    appointment: {}
                }
            }

        case FETCHED_ONGOING_RESERVATIONS:
            return {
                ...state,
                ongoingReservation: {
                    exists: !_.isEmpty(action.payload), // is empty string in case no ongoing reservation exists
                    isLoading: false,
                    appointment: action.payload
                }
            };

        case CLEAR_ONGOING_RESERVATIONS:
            return {
                ...state,
                ongoingReservation: {
                    exists: false,
                    isLoading: false,
                    appointment: {}
                }
            };

        default:
            return state
    }
}
